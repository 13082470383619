
import { defineComponent, ref, onMounted, nextTick, onBeforeMount } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { StripeElements, StripeElement } from "vue-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

export default defineComponent({
  name: "CardOnly",
  components: {
    Field,
    Form,
    ErrorMessage,
    StripeElements,
    StripeElement,
    // StripeElementCard
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const profile = ref<any>(false);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const loading = ref<boolean>(false);
    const validNumber = ref<any>();
    const page = ref<string>("");
    const registration = Yup.object().shape({
      amount: Yup.string().required().label("Amount"),
    });
    const formData = ref<any>({});
    const stripeKey = ref(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
    const instanceOptions = ref({});
    const elementsOptions = ref({});
    const cardOptions = ref({ value: { postalCode: "" } });
    const stripeLoaded = ref(false);
    const card = ref();
    const elms = ref();
    const cardType = ref();
    const loginData = ref<any>([]);
    const cards = ref<any>([]);

    // const pulishableKey = ref('pk_test_51KU37gSIisLCyaZpL5P3MJU0hRCn0yAVnCnPMiNeSxGRHqKDHvtQk4oMi1nWfwdaHviCk4VHl0wl6igVhqhBXnGZ00EBVN7CJh');
    // const stripe = ref(Stripe('pk_test_51KU37gSIisLCyaZpL5P3MJU0hRCn0yAVnCnPMiNeSxGRHqKDHvtQk4oMi1nWfwdaHviCk4VHl0wl6igVhqhBXnGZ00EBVN7CJh'));
    // const pulishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    // const token = ref<any>(null);
    onBeforeMount(() => {
      const stripePromise = loadStripe(stripeKey.value);
      stripePromise.then(() => {
        stripeLoaded.value = true;
      });
    });

    onMounted(() => {
      const data = route.params.id;
      if (data) {
        setCurrentPageBreadcrumbs("Buy Plan", []);
      } else {
        setCurrentPageBreadcrumbs("Add User", ["User"]);
      }
      store.dispatch(Actions.GET_AUTH_USER).then((data) => {
        profile.value = data;
      });
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
      const id = route.params.id;
      if (id) {
        getUser(id);

        page.value = "Edit";
      } else {
        page.value = "Add";
      }
      getOldCard();
    });
    const getUser = (id) => {
      var request = {
        url: `oneplan/${id}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          formData.value = data.data;
        }
      });
    };

    const getOldCard = () => {
      var request = {
        url: `plan/old-card`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          cards.value = data.data.data;
          console.log(cards.value);
        }
      });
    };

    const tabonclick = (data) => {
      // console.log('tab on click',data);
      cardType.value = data;
    };

    const onSubmitRegister = (values, { resetForm }) => {
      // submitButton.value!.disabled = true;
      // submitButton.value?.setAttribute("data-kt-indicator", "on");
      loading.value = true;

      const cardElement = card.value.stripeElement;
      elms.value.instance
        .createToken(cardElement)
        .then((result) => {
          var type = cardType.value;

          var postData = {
            amount: values.amount,
            discount_coupon: values.discount_coupon,
            subscription_id: formData.value.plan_id,
            card_type: type,
            card_id: "",
            card_token: "",
          };

          if(!type){
            type = "new";
          }

          if (type == "new") {
            postData.card_id = result.token.card.id;
            postData.card_token = result.token.id;
            // var postData = {
            //   amount: values.amount,
            //   discount_coupon: values.discount_coupon,
            //   card_id: result.token.card.id,
            //   card_token: result.token.id,
            //   subscription_id: formData.value.plan_id,
            //   card_type: type,
            // };
          } else {
            postData.card_id = values.card;
            // console.log(values);
            // return;
            // var postData = {
            //   amount: values.amount,
            //   discount_coupon: values.discount_coupon,
            //   card_id: values.card,
            //   //card_token: result.token.id,
            //   subscription_id: formData.value.plan_id,
            //   card_type: type,
            // };
          }
          var request = {
            url: "buy-plan",
            data: postData,
          };
          store
            .dispatch(Actions.POST, request)
            .then((data) => {
              if (data) {
                getNewToken();

                Swal.fire("Success", "Plan buy successfully!", "success");
                router.push({ name: "wallet" });
              }
              loading.value = false;
            })
            .catch(() => {
              loading.value = false;
              const [error] = Object.keys(store.getters.getErrors);
              Swal.fire({
                text: store.getters.getErrors[error],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        })
        .catch((error) => {
          console.log(error);

          //if(cardType.value == 'new'){
          loading.value = false;
          Swal.fire("Error", "Please enter valid detail!", "error");
          // }else{

          // }
        });

      const getNewToken = () => {
        store
          .dispatch(Actions.LOGIN2, {})
          .then((data) => {
            console.log(data);
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getErrors);
            Swal.fire({
              text: store.getters.getErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      };

      // var request = {
      //     url: "user",
      //     data: {
      //         name: values.name,
      //         email: values.email,
      //         number: values.number,
      //         password: values.password,
      //         city: values.city,
      //         state: values.state,
      //         zip_code: values.zip_code,
      //         address: values.address,
      //         number_price: values.number_price,
      //         timezone: values.timezone,
      //     },
      // };
      // store

      // .dispatch(Actions.POST, request)
      // .then((data) => {
      //     if (data) {
      //       Swal.fire("Success", "User added successfully!", "success");
      //       router.push({ name: "admin-user" });
      //     }
      //     loading.value = false;
      // })
      // .catch(() => {
      //     const [error] = Object.keys(store.getters.getErrors);
      //     Swal.fire({
      //       text: store.getters.getErrors[error],
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Try again!",
      //       customClass: {
      //         confirmButton: "btn fw-bold btn-light-danger",
      //       },
      //     });
      // });
      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
        submitButton.value!.disabled = false;
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      loading,
      validNumber,
      page,
      getUser,
      stripeKey,
      instanceOptions,
      elementsOptions,
      cardOptions,
      stripeLoaded,
      card,
      elms,
      formData,
      tabonclick,
      cardType,
      cards,
      // pulishableKey,
      // token,
      // tokenCreated,
      // elementRef
    };
  },
});
